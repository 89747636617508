<template>
  <div class="w-full">
    <div class="relative flex flex-wrap items-center w-full gap-6 my-6"  >
      <div class="flex-1">
        <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      </div>
      <ModalConfirm 
        title="Batalkan Pembuatan Persetujuan" 
        message="Informasi yang diisi akan hilang, apakah Anda yakin?" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
      <h4 class="flex flex-wrap justify-center flex-1 flex-auto text-lg font-medium text-gray-darkest">Create Procurement Approval</h4>
      <button @click="expandCollapseAll" class="flex justify-end flex-1 underline cursor-pointer text-tertiary hover:text-tertiary-dark" >{{messageCollapseAll}}</button>
    </div>
    <TemplateFormProcurementApproval :collapse="collapse" @changeCollapse="changeCollapse" :showRecommendation="true"/>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex" :class="quotationDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <div class="flex-auto">
          <ButtonGista type="secondary" :class="quotationDetailContainerWidth > 640? '' : 'w-full'" color="primary" @click="() => isOpenSaveAsDraft = true">Save As Draft</ButtonGista>
          <ModalConfirm 
            title="Simpan sebagai Draft" 
            message="Anda dapat melanjutkan draft di tab ‘Approval’" 
            icon="saved" textButton="Save As Draft" 
            :isOpen="isOpenSaveAsDraft" 
            @changeIsOpen="() => isOpenSaveAsDraft = false" 
            @click="saveAsDraft">
          </ModalConfirm>
        </div>
        <div class="flex-none gap-6" :class="quotationDetailContainerWidth > 640 ? 'grid grid-cols-2' : 'flex flex-col'">
          <ButtonGista type="secondary" color="primary" @click="clickPreview" v-if="menuRfqApprovalPreview?.canCreate">Preview</ButtonGista>
          <ButtonGista v-if="!isSubmitted" type="primary" class="w-full" color="success" @click="() => isOpenSubmit = true" >Create</ButtonGista>
          <ModalConfirm 
            title="Buat Persetujuan" 
            message="Pastikan seluruh informasi sudah benar" 
            icon="submitted" 
            textButton="Create" 
            :isOpen="isOpenSubmit" 
            :isBackButton="true"
            @changeIsOpen="() => isOpenSubmit = false"
            :hideClose="false"
            @click="submitApproval"
            colorButton="success"></ModalConfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { MESSAGE_SUCCESS_CREATE_APPROVAL } from '@/core/constant/successMessage'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import ModalConfirm from '@/core/components/modal/Confirm'
import TemplateFormProcurementApproval from '@/core/components/rfq/procurementApproval/TemplateFormProcurementApproval.vue'
import { PATH_ROOT } from '@/core/constant/routeName'

export default {
  name: "CreateProcurementApproval",
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: true,
        negoResults: true,
        projectApproval: true,
        costBudget: true,
        attachments: true,
        recommendation: true,
        checker: true,
        approver: true,
      },
      isOpenSaveAsDraft: false,
      isOpenSubmit: false,
      isOpenCancel: false,
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApprovalRfq/SET_ERROR_MAX_LENGTH', val)
      }
    },
    errorSelectOne: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorSelectOne
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_ERR_SELECT_ONE', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    },
    menuRfqApprovalPreview() {
			return this.$store.getters['dashboard/menuRfqApprovalPreview']
		},
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    async saveAsDraft() {
      try {
        this.modal.modalLoading = true
        const checked = await this.validationDraft()
        if(!checked) return this.modal.modalLoading = false;
        const response = await this.$store.dispatch('procurementApprovalRfq/postApproval', { isSubmit: false, })
        if (response?.status) {
          this.$emit('setShowButtonContinueDraft', true)
          await this.$store.dispatch('procurementApprovalRfq/getApproval', { rfqId: this.$route.params.quotationId })
        }
        this.modal.modalLoading = false
      } catch (e) {
        this.modal.modalLoading = false
      }
    },
    setErrSelectOne() {
      const data = this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList
      if(!data.some(e => e.isSelected)) {
        this.errorSelectOne = true
        return true
      } else {
        this.errorSelectOne = false
        return false
      }
    },
    messageErrorValidate() {
      const errorVeeValidate = this.$refs.formProcurementApproval.errors
      return Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
    },
    async submitApproval() {
      try {
        this.modal.modalLoading = true
        const errorInformation = await this.checkErrorInformationSubmit()
        const checkErrorNegotiationResult = await this.setErrorNegotiationResultSubmit()
        const checkErrorQtyOrder = await this.setErrSelectOne()
        const MESSAGE_ERROR_SELECT_ONE = checkErrorQtyOrder ? ['Negotiation Results'] : []
        const checkErrorRecommendation = await this.$store.dispatch('procurementApprovalRfq/checkErrorRecommendation')
        const MESSAGE_ERROR_RECOMMENDATION = await this.$store.dispatch('procurementApprovalRfq/messageErrorRecommendation')
        const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApprovalRfq/checkErrorCostBudgetInformation', { isSubmit: true })
        const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
        if (errorInformation.length || checkErrorNegotiationResult.length || checkErrorQtyOrder || !checkErrorRecommendation || !checkErrorCostBudgetInformation) {
          this.modal.modalLoading = false
          const MESSAGE_ERROR_INFORMATION = errorInformation
          const MESSAGE_ERROR_NEGOTIATION_RESULT = checkErrorNegotiationResult
          const message = [ ...MESSAGE_ERROR_INFORMATION, ...MESSAGE_ERROR_NEGOTIATION_RESULT, ...MESSAGE_ERROR_SELECT_ONE, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION, ...MESSAGE_ERROR_RECOMMENDATION].join(', ')
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
          this.isOpenSubmit = false
          return
				}
        const response = await this.$store.dispatch('procurementApprovalRfq/postApproval', { isSubmit: true })
        if (response?.status) {
          this.$emit('refetchApprovalLog')
          this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_APPROVAL }
        }
        this.modal.modalLoading = false
      } catch (e) {
			  this.modal.modalLoading = false
        console.log(e)
      }
    },
    checkErrorInformationSubmit() {
      let error = []
      if (this.dataProcurementApproval.introductionBackground?.length === 0 || this.dataProcurementApproval.introductionBackground === null) {
        this.errorMaxLength['Introduction / Background & Needs'] = MESSAGE_EMPTY_FIELD
      }
      if (this.dataProcurementApproval.projectGoals?.length === 0 || this.dataProcurementApproval.projectGoals === null) {
        this.errorMaxLength['Project Goals'] = MESSAGE_EMPTY_FIELD
      }
      if (this.errorMaxLength['Introduction / Background & Needs']) {
        error.push('Introduction / Background & Needs')
      }
      if (this.errorMaxLength['Project Goals']) {
        error.push('Project Goals')
      }
      return error
    },
    checkErrorSaveAsDraft() {
      let error = []
      if (this.errorMaxLength['Introduction / Background & Needs'] && this.errorMaxLength['Introduction / Background & Needs'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Introduction / Background & Needs')
      }
      if (this.errorMaxLength['Project Goals'] && this.errorMaxLength['Project Goals'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Project Goals')
      }
      return error
    },
    setErrorNegotiationResultSubmit() {
      let error = []
      if ((this.dataProcurementApproval.negotiationResult.negotiationResultDetails.exchangeRate === 0 || !this.dataProcurementApproval.negotiationResult.negotiationResultDetails.exchangeRate) && this.dataProcurementApproval.negotiationResult.negotiationResultDetails.currency?.toLowerCase() !== 'idr') {
        this.dataProcurementApproval.negotiationResult.errorMaxLength['Exchange Rate'] = 'Minimal 1'
      } 
      if (this.dataProcurementApproval.negotiationResult.errorMaxLength['Note Internal BCA']) {
        error.push('Note Internal BCA')
      }
      if (this.dataProcurementApproval.negotiationResult.errorMaxLength['Exchange Rate']) {
        error.push('Exchange Rate')
      }
      return error
    },
    checkErrorNegotiationResultSaveAsDraft() {
      let error = []
      if (this.dataProcurementApproval.negotiationResult.errorMaxLength['Note Internal BCA'] && this.dataProcurementApproval.negotiationResult.errorMaxLength['Note Internal BCA'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Note Internal BCA')
      }
      if (this.dataProcurementApproval.negotiationResult.errorMaxLength['Exchange Rate'] && ![MESSAGE_EMPTY_FIELD, 'Minimal 1'].includes(this.dataProcurementApproval.negotiationResult.errorMaxLength['Exchange Rate'])) {
        error.push('Exchange Rate')
      }
      return error
    },
    async validationDraft() {
      this.modal.modalLoading = true
      const errorInformation = await this.checkErrorSaveAsDraft()
      const errorNegotiationResult = await this.checkErrorNegotiationResultSaveAsDraft()
      const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApprovalRfq/checkErrorCostBudgetInformation', { isSubmit: false })
      const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
      if (errorInformation.length || errorNegotiationResult.length || !checkErrorCostBudgetInformation) {
        this.modal.modalLoading = false
        const MESSAGE_ERROR_INFORMATION = errorInformation
        const MESSAGE_ERROR_NEGOTIATION_RESULT = errorNegotiationResult
        const message = [ ...MESSAGE_ERROR_INFORMATION, ...MESSAGE_ERROR_NEGOTIATION_RESULT, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION ].join(', ')
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
        this.isOpenSaveAsDraft = false
        return false
      } else {
        return true
      }
    },
    async clickPreview() {
      try {
        this.modal.modalLoading = true
        const checked = await this.validationDraft()
        if(!checked) return this.modal.modalLoading = false;
        const response = await this.$store.dispatch('procurementApprovalRfq/postApprovalPreview')
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (e) {
        this.modal.modalLoading = false
      }
    },
    async backToHome() {
      const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
      this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
    },
    async clickCancel() {
      if (!this.dataProcurementApproval.id) {
        this.backToHome()
        return
      }
      const response = await this.$store.dispatch('procurementApprovalRfq/cancelProcurementApproval', { approvalId: this.dataProcurementApproval.id })
      if (response?.status) {
        this.backToHome()
      }
    }
  },
  async mounted() {
    const res = await this.$store.dispatch('procurementApprovalRfq/getApproval', { rfqId: this.$route.params.quotationId })
    if (res?.status === 200) {
      if (this.dataProcurementApproval.id > 0) {
        this.$store.dispatch('procurementApprovalRfq/getApprovalLog', { approvalId: this.dataProcurementApproval.id })
      }
    }
  },
  components:{
    ModalConfirm,
    TemplateFormProcurementApproval
  }
}
</script>

