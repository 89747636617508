<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="quotationDetailContainerWidth < 640? 'gap-2.5 flex-col items-start' : 'items-center '">
      <div class="relative flex flex-wrap items-center w-full gap-6">
        <span class="flex-1 text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
        <h4 class="flex flex-wrap justify-center flex-1 flex-auto text-lg font-medium text-gray-darkest">Create LOI</h4>
        <div class="flex-1"></div>
      </div>
    </div>
    <ModalConfirm 
        title="Batalkan Pembuatan LOI?" 
        message="Informasi yang diisi akan hilang, apakah anda yakin?" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
    <div>
      <Information/>
      <CardLoi/>
      <Approval />
    </div>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-between" :class="quotationDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <SaveAsDraftLoi @click="saveAsDraft" />
        <div class="flex gap-6" :class="quotationDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <SubmitLoi
            title="Buat LOI"
            @click="submitSktLoi" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SaveAsDraftLoi from '@/core/components/rfq/loi/SaveAsDraftLoi.vue'
import SubmitLoi from '@/core/components/rfq/loi/SubmitLoi.vue'
import { MESSAGE_SUCCESS_CREATE_LOI } from '@/core/constant/successMessage'
import Information from '@/core/components/rfq/loi/Information.vue'
import Approval from '@/core/components/rfq/loi/Approval.vue'
import CardLoi from '@/core/components/rfq/loi/CardLoi.vue'
import { PATH_ROOT } from '@/core/constant/routeName'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "CreateLoi",
  data() {
    return {
      isOpenCancel: false,
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    approvals() {
      return this.$store.state.loiRfq.approvals
    },
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_LOI', value)
      }
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods:{
    setErrorApprovals() {
      if (!this.approvals.APPROVER_DLOG?.search) {
        this.draftLoi.error['Approver ITP'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftLoi.error['Approver ITP'] = ''
      }
      if (!this.approvals.APPROVER_GSIT?.search) {
        this.draftLoi.error['Approver GSIT'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftLoi.error['Approver GSIT'] = ''
      }
    },
    setErrorVendor() {
      if (!this.draftLoi.vendorPic) {
        this.draftLoi.error['PIC Vendor'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftLoi.error['PIC Vendor'] = ''
      }
      if (!this.draftLoi.vendorEmail) {
        this.draftLoi.error['Email Vendor'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftLoi.error['Email Vendor'] = ''
      }
    },
    setError() {
      this.setErrorApprovals()
      this.setErrorVendor()
    },
    checkErrorSaveAsDraft() {
      return Object.keys(this.draftLoi.error).filter(e => this.draftLoi.error[e] && this.draftLoi.error[e] !== MESSAGE_EMPTY_FIELD)
    },
    checkErrorSubmit() {
      return Object.keys(this.draftLoi.error).filter(e => this.draftLoi.error[e])
    },
    async saveAsDraft() {
      this.modal.modalLoading = true
      const checkErrorSaveAsDraft = await this.checkErrorSaveAsDraft()
      const messageError = checkErrorSaveAsDraft.join(', ')
      if (checkErrorSaveAsDraft.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = this.draftLoi.lastModifiedAt? await this.$store.dispatch('loiRfq/updateDraftLoi') : await this.$store.dispatch('loiRfq/saveAsDraftLoi')
      if (response?.status < 300) {
        this.$emit('setShowButtonContinueDraft', true)
        await this.$store.dispatch('projectLog/refetchLoiRfqLog', { rfqId: this.$route.params.quotationId })
        this.modal.modalLoading = false
      } else {
        this.modal.modalLoading = false
      }
    },
    async submitSktLoi() {
      this.modal.modalLoading = true
      await this.setError()
      const checkErrorSubmit = await this.checkErrorSubmit()
      const messageError = checkErrorSubmit.join(', ')
      if (checkErrorSubmit.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = this.draftLoi.lastModifiedAt? await this.$store.dispatch('loiRfq/updateSubmitLoi') : await this.$store.dispatch('loiRfq/submitDraftLoi')
      if (response?.status) {
        await this.$store.dispatch('loiRfq/getLoi', { rfqId: this.$route.params.quotationId })
        await this.$store.dispatch('projectLog/refetchLoiRfqLog', { rfqId: this.$route.params.quotationId })
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_LOI }
        this.modal.modalLoading = false
      } else {
        this.modal.modalLoading = false
      }
    },
    async clickCancel() {
      const response = await this.$store.dispatch('loiRfq/cancelLoi', { rfqId: this.$route.params.quotationId })
      if (response?.status) {
        const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			  this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
      }
    },
    async clickPreview() {
      try {
        this.modal.modalLoading = true
        const response = await this.$store.dispatch('loiRfq/postLoiPreview')
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (e) {
        this.modal.modalLoading = false
      }
    },
  },
  mounted() {
  },
  components:{
    SubmitLoi,
    SaveAsDraftLoi,
    Information,
    Approval,
    CardLoi,
    ModalConfirm
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>