<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="quotationDetailContainerWidth < 640 && 'items-center '">
      <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      <ModalConfirm 
        title="Batalkan Perubahan?" 
        message="Perubahan tidak akan tersimpan" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
      <div class="flex justify-center w-full">
        <h4 class="text-lg font-medium text-gray-darkest">Edit SPP</h4>
      </div>
    </div>
    <div>
      <ValidationObserver ref="formSpp">
        <Information />
        <CardSpp />
        <Approval />
      </ValidationObserver>
    </div>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-end">
        <div class="flex" :class="quotationDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <SubmitSpp 
            title="Kirim Perubahan SPP?"
            @click="submitSpp" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SubmitSpp from '@/core/components/rfq/spp/SubmitSpp.vue'
import { MESSAGE_SUCCESS_CREATE_SPP } from '@/core/constant/successMessage'
import Information from '@/core/components/rfq/spp/Information.vue'
import Approval from '@/core/components/rfq/spp/Approval.vue'
import CardSpp from '@/core/components/rfq/spp/CardSpp.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "EditSpp",
  data() {
    return {
      isOpenCancel: false,
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    dataSpp: {
      get() {
        return this.$store.state.sppRfq.dataSpp
      },
      set(value) {
        this.$store.commit('sppRfq/SET_DATA_SPP', value)
      }
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    approvals() {
      return this.$store.state.sppRfq.approvals
    },
  },
  methods:{
    setErrorApprovals() {
      if (!this.approvals.APPROVER_DLOG?.search) {
        this.dataSpp.error['Approver DLOG'] = MESSAGE_EMPTY_FIELD
      }
      if (!this.approvals.APPROVER_GSIT?.search) {
        this.dataSpp.error['Approver GSIT'] = MESSAGE_EMPTY_FIELD
      }
    },
    setErrorVendors() {
      const vendor = this.dataSpp
      if (!vendor.vendorPic) vendor.error['PIC Vendor'] = MESSAGE_EMPTY_FIELD
      if (!vendor.vendorEmail) vendor.error['Email Vendor'] = MESSAGE_EMPTY_FIELD
    },
    setErrorAll(isSubmit) {
      this.setErrorApprovals()
      this.setErrorVendors()
      if(isSubmit && this.dataSpp?.attachments?.filter(e => e.fileRef === 'cancellationRequest' && !e.isDeleted).length === 0) {
        this.dataSpp.error['Cancellation Request'] = true
      }
    },
    async checkErrorSubmit(isSubmit) {
      await this.setErrorAll(isSubmit)
      const errorApprovals = Object.keys(this.dataSpp.error).filter(e => this.dataSpp.error[e])
      return errorApprovals
      
    },
    async submitSpp() {
      this.modal.modalLoading = true
      const checkErrorSubmit = await this.checkErrorSubmit(true)
      const messageError = checkErrorSubmit.join(', ')
      if (checkErrorSubmit.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = await this.$store.dispatch('sppRfq/updateSubmitSpp')
      if (response?.status) {
        await this.$store.dispatch('sppRfq/getSpp', { rfqId: this.$route.params.quotationId })
        this.$store.dispatch('projectLog/refetchSppRfqLog', { rfqId: this.$route.params.quotationId })
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_SPP }
        this.$store.commit('sppRfq/SET_MAIN_PAGE', '')
        this.modal.modalLoading = false
      } else {
        this.modal.modalLoading = false
      }
    },
    async clickCancel() {
      this.$store.commit('sppRfq/SET_MAIN_PAGE', '')
      await this.$store.dispatch('sppRfq/getSpp', { rfqId: this.$route.params.quotationId })
    },
  },
  components:{
    SubmitSpp,
    Information,
    Approval,
    CardSpp,
    ModalConfirm,
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>